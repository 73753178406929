define("discourse/plugins/discourse-docs/discourse/components/docs-topic-list-item", ["exports", "@ember/component", "@ember/template", "@ember-decorators/component", "discourse-common/lib/raw-handlebars-helpers", "discourse-common/lib/raw-templates"], function (_exports, _component, _template, _component2, _rawHandlebarsHelpers, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DocsTopicListItem = dt7948.c(class DocsTopicListItem extends _component.default {
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.renderTopicListItem();
    }
    renderTopicListItem() {
      const template = (0, _rawTemplates.findRawTemplate)("docs-topic-list-item");
      if (template) {
        this.set("topicListItemContents", (0, _template.htmlSafe)(template(this, _rawHandlebarsHelpers.RUNTIME_OPTIONS)));
      }
    }
  }, [(0, _component2.classNameBindings)(":topic-list-item"), (0, _component2.tagName)("tr")]);
  var _default = _exports.default = DocsTopicListItem;
});