define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-anniversaries", ["exports", "@ember/service", "discourse/routes/discourse", "I18n"], function (_exports, _service, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CakedayAnniversaries extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    beforeModel() {
      if (!this.siteSettings.cakeday_enabled) {
        this.router.transitionTo("unknown", window.location.pathname.replace(/^\//, ""));
      }
    }
    titleToken() {
      return _I18n.default.t("anniversaries.title");
    }
  }
  _exports.default = CakedayAnniversaries;
});