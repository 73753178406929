define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-birthdays-upcoming", ["exports", "@ember/controller", "@ember/object", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _object, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CakedayBirthdaysUpcomingController extends _controller.default {
    title() {
      const dateFormat = _I18n.default.t("dates.full_no_year_no_time");
      return _I18n.default.t("birthdays.upcoming.title", {
        start_date: moment().add(2, "days").format(dateFormat),
        end_date: moment().add(2, "days").add(1, "week").format(dateFormat)
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "title", [_decorators.default]))();
    loadMore() {
      this.get("model").loadMore();
    }
    static #_2 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
  }
  _exports.default = CakedayBirthdaysUpcomingController;
});