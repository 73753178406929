define("discourse/plugins/discourse-canned-replies/controllers/new-reply", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse/lib/show-modal", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/ajax-error"], function (_exports, _controller, _modalFunctionality, _showModal, _ajax, _decorators, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    newTitle: "",
    newContent: "",
    onShow() {
      this.setProperties({
        newTitle: "",
        newContent: ""
      });
    },
    disableSaveButton(newTitle, newContent) {
      return newTitle === "" || newContent === "";
    },
    actions: {
      save() {
        (0, _ajax.ajax)("/canned_replies", {
          type: "POST",
          data: {
            title: this.newTitle,
            content: this.newContent
          }
        }).then(() => {
          this.send("closeModal");
          if (this.site.mobileView) {
            (0, _showModal.default)("canned-replies");
          } else {
            this.appEvents.trigger("canned-replies:show");
          }
        }).catch(_ajaxError.popupAjaxError);
      },
      cancel() {
        this.send("closeModal");
        if (this.site.mobileView) {
          (0, _showModal.default)("canned-replies");
        } else {
          this.appEvents.trigger("canned-replies:show");
        }
      }
    }
  }, [["method", "disableSaveButton", [(0, _decorators.default)("newTitle", "newContent")]]]));
});