define("discourse/plugins/discourse-docs/discourse/docs-route-map", ["exports", "discourse/plugins/discourse-docs/lib/get-docs"], function (_exports, _getDocs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    const docsPath = (0, _getDocs.getDocs)();
    this.route("docs", {
      path: "/" + docsPath
    }, function () {
      this.route("index", {
        path: "/"
      });
    });
  }
});