define("discourse/plugins/discourse-data-explorer/discourse/controllers/group-reports-show", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/components/modal/bookmark", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/bookmark-form-data", "discourse/models/bookmark", "discourse-common/utils/decorators", "discourse/plugins/discourse-data-explorer/discourse/components/param-input-form"], function (_exports, _tracking, _controller, _object, _service, _bookmark, _ajax, _ajaxError, _bookmarkFormData, _bookmark2, _decorators, _paramInputForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupReportsShowController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "showResults", [_tracking.tracked], function () {
      return false;
    }))();
    #showResults = (() => (dt7948.i(this, "showResults"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "results", [_tracking.tracked], function () {
      return this.model.results;
    }))();
    #results = (() => (dt7948.i(this, "results"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "queryGroupBookmark", [_tracking.tracked], function () {
      return this.queryGroup?.bookmark;
    }))();
    #queryGroupBookmark = (() => (dt7948.i(this, "queryGroupBookmark"), void 0))();
    queryParams = ["params"];
    form = null;
    explain = false;
    get parsedParams() {
      return this.params ? JSON.parse(this.params) : null;
    }
    get hasParams() {
      return this.model.param_info.length > 0;
    }
    get bookmarkLabel() {
      return this.queryGroupBookmark ? "bookmarked.edit_bookmark" : "bookmarked.title";
    }
    get bookmarkIcon() {
      if (this.queryGroupBookmark && this.queryGroupBookmark.reminder_at) {
        return _bookmark2.WITH_REMINDER_ICON;
      }
      return _bookmark2.NO_REMINDER_ICON;
    }
    get bookmarkClassName() {
      return this.queryGroupBookmark ? ["query-group-bookmark", "bookmarked"].join(" ") : "query-group-bookmark";
    }
    async run() {
      try {
        let params = null;
        if (this.hasParams) {
          params = await this.form.submit();
          if (params == null) {
            return;
          }
        }
        this.loading = true;
        this.showResults = false;
        const stringifiedParams = JSON.stringify(params);
        this.router.transitionTo({
          queryParams: {
            params: params ? stringifiedParams : null
          }
        });
        const response = await (0, _ajax.ajax)(`/g/${this.get("group.name")}/reports/${this.model.id}/run`, {
          type: "POST",
          data: {
            params: stringifiedParams,
            explain: this.explain
          }
        });
        this.results = response;
        if (!response.success) {
          return;
        }
        this.showResults = true;
      } catch (error) {
        if (error.jqXHR?.status === 422 && error.jqXHR.responseJSON) {
          this.results = error.jqXHR.responseJSON;
        } else if (!(error instanceof _paramInputForm.ParamValidationError)) {
          (0, _ajaxError.popupAjaxError)(error);
        }
      } finally {
        this.loading = false;
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "run", [_decorators.bind]))();
    toggleBookmark() {
      const modalBookmark = this.queryGroupBookmark || this.store.createRecord("bookmark", {
        bookmarkable_type: "DiscourseDataExplorer::QueryGroup",
        bookmarkable_id: this.queryGroup.id,
        user_id: this.currentUser.id
      });
      return this.modal.show(_bookmark.default, {
        model: {
          bookmark: new _bookmarkFormData.BookmarkFormData(modalBookmark),
          afterSave: bookmarkFormData => {
            const bookmark = this.store.createRecord("bookmark", bookmarkFormData.saveData);
            this.queryGroupBookmark = bookmark;
            this.appEvents.trigger("bookmarks:changed", bookmarkFormData.saveData, bookmark.attachedTo());
          },
          afterDelete: () => {
            this.queryGroupBookmark = null;
          }
        }
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "toggleBookmark", [_object.action]))();
    onRegisterApi(form) {
      this.form = form;
    }
    static #_10 = (() => dt7948.n(this.prototype, "onRegisterApi", [_object.action]))();
  }
  _exports.default = GroupReportsShowController;
});