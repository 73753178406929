define("discourse/plugins/discourse-canned-replies/controllers/edit-reply", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse/lib/show-modal", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "I18n", "@ember/service"], function (_exports, _controller, _modalFunctionality, _showModal, _ajax, _decorators, _ajaxError, _I18n, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    dialog: (0, _service.inject)(),
    replyTitle: "",
    replyContent: "",
    replyId: "",
    saving: null,
    onShow() {
      this.set("saving", null);
    },
    savingLabel(saving) {
      return saving === null ? "save" : saving ? "saving" : "saved";
    },
    disableSaveButton(replyTitle, replyContent, saving) {
      return saving || replyTitle === "" || replyContent === "";
    },
    actions: {
      save() {
        this.set("saving", true);
        (0, _ajax.ajax)(`/canned_replies/${this.replyId}`, {
          type: "PATCH",
          data: {
            title: this.replyTitle,
            content: this.replyContent
          }
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("saving", false);
          this.appEvents.trigger("canned-replies:show");
        });
      },
      remove() {
        this.dialog.deleteConfirm({
          message: _I18n.default.t("canned_replies.edit.remove_confirm"),
          didConfirm: () => {
            return (0, _ajax.ajax)(`/canned_replies/${this.replyId}`, {
              type: "DELETE"
            }).then(() => {
              this.send("closeModal");
              if (this.site.mobileView) {
                (0, _showModal.default)("canned-replies");
              } else {
                this.appEvents.trigger("canned-replies:show");
              }
            }).catch(_ajaxError.popupAjaxError);
          }
        });
      },
      cancel() {
        this.send("closeModal");
        if (this.site.mobileView) {
          (0, _showModal.default)("canned-replies");
        } else {
          this.appEvents.trigger("canned-replies:show");
        }
      }
    }
  }, [["method", "savingLabel", [(0, _decorators.default)("saving")]], ["method", "disableSaveButton", [(0, _decorators.default)("replyTitle", "replyContent", "saving")]]]));
});