define("discourse/plugins/discourse-docs/discourse/routes/docs-index", ["exports", "discourse/routes/discourse", "I18n", "discourse/plugins/discourse-docs/discourse/models/docs"], function (_exports, _discourse, _I18n, _docs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DocsIndex extends _discourse.default {
    queryParams = {
      ascending: {
        refreshModel: true
      },
      filterCategories: {
        refreshModel: true
      },
      filterTags: {
        refreshModel: true
      },
      filterSolved: {
        refreshModel: true
      },
      orderColumn: {
        refreshModel: true
      },
      selectedTopic: {
        refreshModel: true
      },
      searchTerm: {
        replace: true,
        refreshModel: true
      }
    };
    model(params) {
      this.controllerFor("docs.index").set("isLoading", true);
      return _docs.default.list(params).then(result => {
        this.controllerFor("docs.index").set("isLoading", false);
        return result;
      });
    }
    titleToken() {
      const model = this.currentModel;
      const pageTitle = _I18n.default.t("docs.title");
      if (model.topic.title && model.topic.category_id) {
        const title = model.topic.unicode_title || model.topic.title;
        const categoryName = this.site.categories.findBy("id", model.topic.category_id).name;
        return `${title} - ${categoryName} - ${pageTitle}`;
      } else {
        return pageTitle;
      }
    }
    setupController(controller, model) {
      controller.set("topic", model.topic);
      controller.set("model", model);
    }
  }
  _exports.default = DocsIndex;
});