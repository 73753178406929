define("discourse/plugins/discourse-reactions/discourse/widgets/discourse-reactions-state-panel", ["exports", "virtual-dom", "discourse/widgets/widget"], function (_exports, _virtualDom, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-reactions-state-panel", {
    tagName: "div.discourse-reactions-state-panel",
    buildKey: attrs => `discourse-reactions-state-panel-${attrs.post.id}`,
    buildClasses(attrs) {
      const classes = [];
      if (attrs.post && attrs.post.reactions) {
        const maxCount = Math.max(...attrs.post.reactions.mapBy("count"));
        const charsCount = maxCount.toString().length;
        classes.push(`max-length-${charsCount}`);
      }
      if (attrs.statePanelExpanded) {
        classes.push("is-expanded");
      }
      return classes;
    },
    mouseOut() {
      if (!window.matchMedia("(hover: none)").matches) {
        this.callWidgetFunction("scheduleCollapse", "collapseStatePanel");
      }
    },
    mouseOver() {
      if (!window.matchMedia("(hover: none)").matches) {
        this.callWidgetFunction("cancelCollapse");
      }
    },
    showUsers(reactionId) {
      if (!this.state.displayedReactionId) {
        this.state.displayedReactionId = reactionId;
      } else if (this.state.displayedReactionId === reactionId) {
        this.hideUsers();
      } else if (this.state.displayedReactionId !== reactionId) {
        this.state.displayedReactionId = reactionId;
      }
    },
    hideUsers() {
      this.state.displayedReactionId = null;
    },
    defaultState() {
      return {
        displayedReactionId: null
      };
    },
    html(attrs) {
      if (!attrs.statePanelExpanded || !attrs.post.reactions.length) {
        return;
      }
      const reactions = Object.keys(attrs.reactionsUsers).length ? (0, _virtualDom.h)("div.counters", attrs.post.reactions.map(reaction => this.attach("discourse-reactions-state-panel-reaction", {
        reaction,
        users: attrs.reactionsUsers[reaction.id],
        post: attrs.post,
        isDisplayed: reaction.id === this.state.displayedReactionId
      }))) : (0, _virtualDom.h)("div.spinner-container", (0, _virtualDom.h)("div.spinner.small"));
      return (0, _virtualDom.h)("div.container", reactions);
    }
  });
});