define("discourse/plugins/discourse-docs/discourse/templates/components/docs-topic", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @label="docs.topic.back"
    class="docs-nav-link return"
    @action={{this.return}}
  />
  
  <div class="topic-content">
    <h1>{{html-safe this.topic.fancyTitle}}</h1>
  
    {{! template-lint-disable no-capital-arguments }}
    <MountWidget @widget="post" @model={{this.model}} @args={{this.post}} />
  </div>
  
  <a class="docs-nav-link more" href="/t/{{this.topic.id}}">
    {{d-icon "far-comment"}}
    {{i18n "docs.topic.navigate_to_topic"}}
  </a>
  
  <span>
    <PluginOutlet @name="after-docs-topic" @connectorTagName="div" />
  </span>
  */
  {
    "id": "v2gCq17a",
    "block": "[[[8,[39,0],[[24,0,\"docs-nav-link return\"]],[[\"@label\",\"@action\"],[\"docs.topic.back\",[30,0,[\"return\"]]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"topic-content\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[28,[35,3],[[30,0,[\"topic\",\"fancyTitle\"]]],null]],[13],[1,\"\\n\\n\"],[1,\"  \"],[8,[39,4],null,[[\"@widget\",\"@model\",\"@args\"],[\"post\",[30,0,[\"model\"]],[30,0,[\"post\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,3],[14,0,\"docs-nav-link more\"],[15,6,[29,[\"/t/\",[30,0,[\"topic\",\"id\"]]]]],[12],[1,\"\\n  \"],[1,[28,[35,6],[\"far-comment\"],null]],[1,\"\\n  \"],[1,[28,[35,7],[\"docs.topic.navigate_to_topic\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,1],[12],[1,\"\\n  \"],[8,[39,9],null,[[\"@name\",\"@connectorTagName\"],[\"after-docs-topic\",\"div\"]],null],[1,\"\\n\"],[13]],[],false,[\"d-button\",\"div\",\"h1\",\"html-safe\",\"mount-widget\",\"a\",\"d-icon\",\"i18n\",\"span\",\"plugin-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-docs/discourse/templates/components/docs-topic.hbs",
    "isStrictMode": false
  });
});