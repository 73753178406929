define("discourse/plugins/discourse-canned-replies/discourse/templates/components/canned-replies-form", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <form>
    <div class="canned-replies-title-input-wrapper">
      <label><strong>{{i18n "canned_replies.title.name"}}</strong></label>
      <Input class="canned-replies-form-title-input" @value={{title}} />
    </div>
  
    <div class="canned-replies-form-content-wrapper">
      <label><strong>{{i18n "canned_replies.content.name"}}</strong></label>
      <DEditor @class="canned-replies-form-content-input" @value={{content}} />
    </div>
  </form>
  */
  {
    "id": "THFEdULV",
    "block": "[[[10,\"form\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"canned-replies-title-input-wrapper\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[10,\"strong\"],[12],[1,[28,[35,4],[\"canned_replies.title.name\"],null]],[13],[13],[1,\"\\n    \"],[8,[39,5],[[24,0,\"canned-replies-form-title-input\"]],[[\"@value\"],[[30,0,[\"title\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"canned-replies-form-content-wrapper\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[10,\"strong\"],[12],[1,[28,[35,4],[\"canned_replies.content.name\"],null]],[13],[13],[1,\"\\n    \"],[8,[39,6],null,[[\"@class\",\"@value\"],[\"canned-replies-form-content-input\",[30,0,[\"content\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `title` property path was used in the `discourse/plugins/discourse-canned-replies/discourse/templates/components/canned-replies-form.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.title}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `content` property path was used in the `discourse/plugins/discourse-canned-replies/discourse/templates/components/canned-replies-form.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.content}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"form\",\"div\",\"label\",\"strong\",\"i18n\",\"input\",\"d-editor\"]]",
    "moduleName": "discourse/plugins/discourse-canned-replies/discourse/templates/components/canned-replies-form.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});