define("discourse/plugins/discourse-docs/lib/get-docs", ["exports", "discourse/models/site"], function (_exports, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDocs = getDocs;
  function getDocs() {
    return _site.default.currentProp("docs_path") || "docs";
  }
});