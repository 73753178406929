define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-anniversaries-all", ["exports", "@ember/controller", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _controller, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CakedayAnniversariesAllController extends _controller.default {
    queryParams = ["month"];
    month = (() => moment().month() + 1)();
    months() {
      return moment.months().map((month, index) => {
        return {
          name: month,
          value: index + 1
        };
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "months", [_decorators.default]))();
    loadMore() {
      this.get("model").loadMore();
    }
    static #_2 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
  }
  _exports.default = CakedayAnniversariesAllController;
});